<template>
  <div>
    <CRow>
      <CCol col>
        <CCard>
          <CCardHeader>
            <strong> Modulo Tipo de Habitación - Nuevo</strong>
          </CCardHeader>
          <CCardBody>
            <b-form id="Form" @submit.prevent="Validate">
              <b-row>
               

                <b-col md="6">
                  <b-form-group label="Nombre:">
                    <b-form-input
                      type="text"
                      ref="name"
                      v-model="type_room.name"
                      placeholder="Ingrese su nombre"
                    ></b-form-input>
                    <small v-if="errors.name" class="form-text text-danger"
                      >Ingrese un nombre</small
                    >
                  </b-form-group>
                </b-col>
        
                
                <b-col md="6">
                  <b-form-group label="Foto:">
                    <b-form-file
                      @change="onFileChange"
                      placeholder="Seleccione un foto..."
                      drop-placeholder="Suelta la imagen aquí..."
                    ></b-form-file>
                    </b-form-group>
                </b-col>

                
  
                <b-col md="10">
                  <b-form-group label="Descripción :" required>
                    <b-form-textarea
                      v-model="type_room.description"
                      rows="2"
                      max-rows="6"
                    ></b-form-textarea>
                  </b-form-group>
                </b-col>

                <b-col md="2">
                  <b-form-group label="Estado :" label-for="input-1">
                    <select
                      ref="state"
                      v-model="type_room.state"
                      class="form-control"
                    >
                      <option value="1">Activo</option>
                      <option value="0">Inactivo</option>
                    </select>
                  </b-form-group>
                </b-col>
   
                
 

                <b-col md="3"></b-col>
                <b-col md="6">
                  <b-button type="submit" class="form-control btn-primary" >GUARDAR</b-button>
                </b-col>
              </b-row>
            </b-form>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>

<script>
const axios = require("axios").default;
const Swal = require("sweetalert2");
const je = require("json-encrypt");
import { mapState } from "vuex";

export default {
  name: "TypeRoomAdd",
  data() {
    return {
      module: 'TypeRoom',
      type_room: {
        name: "",
        photo: "",
        description: "",
        beds: 1,
        plazas: "",
        maximum_guest: 1,
        minimum_guest: 1,
        state: 1,
      },
      //errors
      errors: {
        name: false,
      },
      validate: false,
    };
  },
  mounted() {
  
  },
  methods: {
    AddTypeRoom,
    Validate,
    onFileChange,
  },

  computed: {
    ...mapState(["url_base"]),
    token: function () {
      let user = window.localStorage.getItem("user");
      user = JSON.parse(JSON.parse(je.decrypt(user)));
      return user.api_token;
    },
  },
};

function onFileChange(e) {
  // e.preventDefault();
    this.type_room.photo = e.target.files[0];
    
}

function AddTypeRoom(_this) {


  

  let me = _this;
  let url = me.url_base + "type-room/add";

  let data = new FormData();
  data.append('name', me.type_room.name);
  data.append('photo', me.type_room.photo);
  data.append('description', me.type_room.description);
  data.append('beds', me.type_room.beds);
  data.append('plazas', me.type_room.plazas);
  data.append('maximum_guest', me.type_room.maximum_guest);
  data.append('minimum_guest', me.type_room.minimum_guest);
  data.append('state', me.type_room.state);

  axios({
    method: "POST",
    url: url,
    data: data,
    headers: {
      "Content-Type": "multipart/form-data",
      token: me.token,
      module: me.module,
      role: 2,
    },
  })
    .then(function (response) {
      if (response.data.status == 201) {
        me.type_room.name = "";
        me.type_room.description = "";
        me.type_room.beds = 1;
        me.type_room.plazas = "";
        me.type_room.maximum_guest = 1;
        me.type_room.minimum_guest = 1;
        me.type_room.state = 1;
        Swal.fire({ icon: 'success', text: 'Se ha registrado el tipo de habitación', timer: 3000,})
      } else {
        Swal.fire({ icon: 'error', text: 'A ocurrido un error', timer: 3000,})
      }
    })
    .catch((error) => {
  
    });
}

function Validate() {
  this.errors.name = this.type_room.name.length == 0 ? true : false;
  this.errors.state = this.type_room.state.length == 0 ? true : false;

  if (this.errors.name) { this.validate = true; return false;}else{ this.validate = false; }
  if (this.errors.state) { this.validate = true; return false;}else{ this.validate = false; }

 let me = this;

    Swal.fire({
      title: 'Esta seguro de registrar el tipo de habitación ?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si, Estoy de Acuerdo!'
    }).then((result) => {
      if (result.isConfirmed) {
        AddTypeRoom(me);
      }
    })

}
</script>
